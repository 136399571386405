import React from "react"
import NoResults from "../noresults"
import AllNews from "./allnews"

type ResultsProps = {
  news: Array<any>
  imageMap?: any
  pagedNews: Array<any>
  page: number
  recordsPerPage: number
  onPageChanged?: any
}

const NewsResults: React.FC<ResultsProps> = ({
  news,
  imageMap,
  pagedNews,
  page,
  recordsPerPage,
  onPageChanged,
}) => (
  <div className="w-full">
    {/* <SearchSummary
          count={events.length}
          single="Event"
          plural="Events"
        /> */}

    {pagedNews.length > 0 ? (
      <AllNews newsitems={pagedNews} imageMap={imageMap} />
    ) : (
      <NoResults text="No awards found." />
    )}

    {/* <Pagination
      page={page}
      totalRecords={events.length}
      recordsPerPage={recordsPerPage}
      pageNeighbors={1}
      onPageChanged={onPageChanged}
    /> */}
  </div>
)

NewsResults.defaultProps = {
  imageMap: {},
  recordsPerPage: 20,
}

export default NewsResults
