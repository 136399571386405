import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
//import CalEventSelector from "../components/calendar/caleventselector"
import useImageMap from "../hooks/imagemap"
import FullDiv from "../components/fulldiv"
//import Breadcrumb from "../components/breadcrumb2"
import FlHdDiv from "../components/flhddiv"
import HTMLDiv from "../components/htmldiv"
import FlatCard from "../components/flatcard"
import GrayLink from "../components/buttons/graylink"
import { useImageName } from "../components/calendar/caleventdetails"
import Row from "../components/row"
import CalEventDate from "../components/calendar/caleventdate"
import MainSideCol from "../components/mainsidecol"
import useSiteMetadata from "../hooks/sitemetadata"
import BlueButton from "../components/buttons/bluebutton"
import dayjs from "dayjs"
import NewsResults from "../components/news/newsresults"
import NewsItem from "../components/news/newsitem"
import PageLayout from "../layouts/pagelayout"

type CalEventProps = {
  award: any
  imageMap: any
}

const SingleAward: React.FC<CalEventProps> = ({ award, imageMap }) => {
  const title = award.frontmatter.title

  const imageName = useImageName(award)

  return (
    <MainSideCol>
      <FullDiv className="md:mr-4">
        <FlatCard>
          <Row isVCentered={false}>
            <div className="w-28 min-w-28 mr-6 mb-8">
              <CalEventDate calEvent={award} />
            </div>
            <FullDiv>
              {/* <div className="mb-8">
                <EventImage name={imageName} imageMap={imageMap} size={72} />
              </div> */}
              <h2 className="m-0">{title}</h2>
              <div className="w-full mt-4">
                <h4>
                  <HTMLDiv o={award} />
                </h4>
              </div>
              <div className="mt-4">
                {/* <FontAwesomeIcon icon={["far", "calendar"]} className={`text-xl text-gray-500 mr-2`} /> */}
                <GrayLink to={award.icsFile}>Add To Calendar</GrayLink>
              </div>
            </FullDiv>
          </Row>
        </FlatCard>
      </FullDiv>

      <></>
    </MainSideCol>
  )
}

export const useNews = (data: any) => {
  const ret = []

  const { icalAPI } = useSiteMetadata()

  for (const { node } of data.news.edges) {
    const calEvent = node

    const title = calEvent.frontmatter.title
    const start = dayjs(calEvent.frontmatter.start)
    const end = dayjs(calEvent.frontmatter.end)
    const formattedTitle = title.toLowerCase().replace(/ /g, "-")
    const formattedDate = start.format("YYYY-MM-DD")

    // calEvent.date = {
    //   year: start.getFullYear(),
    //   month: start.getMonth() + 1,
    //   day: start.getDate(),
    //   start: start.getHours(),
    // }

    calEvent.id = `${formattedTitle}-${formattedDate}`
    calEvent.icsFile = `${icalAPI}?id=${calEvent.id}`

    calEvent.start = start
    calEvent.end = end

    ret.push(calEvent)
  }

  return ret
}

type NewsTemplateProps = {
  path: string
  pageContext: any
  data: any
}

const NewsTemplate: React.FC<NewsTemplateProps> = ({
  path,
  pageContext,
  data,
}) => {
  const { title, crumbs, newsitem } = pageContext

  const allNews: Array<any> = useNews(data)

  const [page, setPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(20)
  const [filterEventTypes, setFilterEventTypes] = useState(
    pageContext.filterEventTypes
  )

  const imageMap = useImageMap(data)

  const offset = 0 //(page - 1) * recordsPerPage

  const handleShowMoreClick = (e: any) => {
    setRecordsPerPage(2 * recordsPerPage)
  }

  const onPageChanged = (data: any) => {
    const { page } = data
    setPage(page)
  }

  let news

  // Filter by types
  if (filterEventTypes.length > 0) {
    news = allNews.filter((e: any) => {
      for (let t of filterEventTypes) {
        if (e.frontmatter.tagList.includes(t)) {
          return true
        }
      }

      return false
    })
  } else {
    news = allNews
  }

  let pagedNews = news.slice(offset, offset + recordsPerPage)

  return (
    <PageLayout
      path={path}
      crumbs={crumbs}
      title={newsitem !== null ? newsitem.frontmatter.title : title}
      nav="News"
    >
      <FlHdDiv>
        <Container>
          <MainSideCol>
            {newsitem !== null ? (
              <NewsItem
                newsitem={newsitem}
                imageMap={imageMap}
                showLink={false}
              />
            ) : (
              <FullDiv>
                <NewsResults
                  news={news}
                  imageMap={imageMap}
                  pagedNews={pagedNews}
                  page={page}
                  recordsPerPage={recordsPerPage}
                  onPageChanged={onPageChanged}
                />

                {recordsPerPage < allNews.length && (
                  <Row isCentered={true} className="mt-8">
                    <div>
                      <BlueButton onClick={handleShowMoreClick}>
                        More Awards
                      </BlueButton>
                    </div>
                  </Row>
                )}
                {/* </FlatCard> */}
              </FullDiv>
            )}
            <></>
          </MainSideCol>
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default NewsTemplate

export const query = graphql`
  query {
    news: allMarkdownRemark(
      sort: { fields: frontmatter___start, order: DESC }
      filter: { fileAbsolutePath: { regex: "/news/" } }
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            photo
            start
            url
            peopleList
            tagList
          }
          excerpt(format: HTML)
          html
        }
      }
    }

    images: allFile(
      filter: {
        absolutePath: { regex: "/images/people/500x500/rounded/" }
        ext: { regex: "/png/" }
      }
    ) {
      edges {
        node {
          name
          ext
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 500, aspectRatio: 1)
          }
        }
      }
    }
  }
`
