import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import { Link } from "gatsby"
import React, { useState } from "react"
import useSiteMetadata from "../../hooks/sitemetadata"
import AltView from "../altview"
import HTMLDiv from "../htmldiv"
import ZoomImage from "../images/zoomimage"
import BlueLink from "../buttons/bluelink"
import Row from "../row"

const title = (newsitem: any, date: any, paths: any, showLink: boolean) => (
  <>
    <div className="my-2">
      <h1>
        {showLink ? (
          <BlueLink
            underline={false}
            to={`${paths.newsPath}/${newsitem.frontmatter.id}`}
          >
            {newsitem.frontmatter.title}
          </BlueLink>
        ) : (
          newsitem.frontmatter.title
        )}
      </h1>
    </div>

    <div className="font-light text-gray-500">
      {date.format("MMMM DD, YYYY")}
    </div>
  </>
)

const taglist = (newsitem: any, paths: any) => (
  <Row className="my-4">
    <div className="mr-2">
      <FontAwesomeIcon icon="tags" />
    </div>

    <ul>
      {newsitem.frontmatter.tagList.map((t: any, index: number) => {
        return (
          <li
            className={`text-sm font-medium bg-gray-100 hover:bg-gray-200 trans-ani rounded px-3 py-1 mt-2 inline ${
              index > 0 ? "ml-2" : ""
            }`}
            key={index}
          >
            <Link to={useTypeUrl(paths.newsPath, t)}>{t}</Link>
          </li>
        )
      })}
    </ul>
  </Row>
)

const useTypeUrl = (root: string, eventType: string) => {
  return `${root}/topics/${eventType.replace(/ /g, "-").toLowerCase()}`
}

export type NewsItemProps = {
  newsitem: any
  showLink?: boolean
  imageMap?: any
}

const BaseNewsItem: React.FC<NewsItemProps> = ({
  newsitem,
  showLink,
  imageMap,
}) => {
  const [hover, setHover] = useState(false)

  const onMouseEnter = (e: any) => {
    setHover(true)
  }

  const onMouseLeave = (e: any) => {
    setHover(false)
  }

  const { paths } = useSiteMetadata()

  const date = dayjs(newsitem.frontmatter.start)

  const fluid =
    newsitem.frontmatter.photo in imageMap
      ? imageMap[newsitem.frontmatter.photo]
      : null

  return (
    <AltView size="lg">
      <>
        {title(newsitem, date, paths, showLink)}

        {taglist(newsitem, paths)}

        {fluid !== null && (
          <div className="w-48 h-48 my-4">
            <ZoomImage
              image={fluid}
              alt={newsitem.frontmatter.title}
              className="w-full h-full"
              extZoom={hover}
            />
          </div>
        )}

        <HTMLDiv o={newsitem} />
      </>

      <Row isVCentered={false}>
        <div className="pr-8">
          {title(newsitem, date, paths, showLink)}
          {taglist(newsitem, paths)}

          <HTMLDiv o={newsitem} />
        </div>

        {fluid !== null && (
          <div className="min-w-48 h-48 rounded-full overflow-hidden">
            <ZoomImage
              image={fluid}
              alt={newsitem.frontmatter.title}
              className="w-full h-full"
              extZoom={hover}
            />
          </div>
        )}
      </Row>
    </AltView>
  )
}

BaseNewsItem.defaultProps = {
  showLink: true,
  imageMap: {},
}

export default BaseNewsItem
