import dayjs from "dayjs"
import React from "react"
import BaseNewsItem, { NewsItemProps } from "./basenewsitem"

const NewsItem: React.FC<NewsItemProps> = ({
  newsitem,
  showLink,
  imageMap,
}) => {
  const date = dayjs(newsitem.frontmatter.start)

  return (
    <BaseNewsItem newsitem={newsitem} showLink={showLink} imageMap={imageMap} />
  )
}

NewsItem.defaultProps = {
  showLink: true,
  imageMap: {},
}

export default NewsItem
