import React from "react"
import {
  EventListItem,
  EventMonthItem,
  sortByMonth,
} from "../calendar/calevents"
import NewsItem from "./newsitem"

type AllNewsProps = {
  newsitems: Array<any>
  imageMap?: object
}

const AllNews: React.FC<AllNewsProps> = ({ newsitems, imageMap }) => {
  const ret: Array<any> = []

  let i: number = 0

  sortByMonth(newsitems).forEach((m: any) => {
    ret.push(<EventMonthItem key={i}>{m.month}</EventMonthItem>)
    ++i
    m.events.forEach((a: any, index: number) => {
      ret.push(
        <EventListItem key={i} index={index} className="md:py-4">
          <NewsItem newsitem={a} imageMap={imageMap} />
        </EventListItem>
      )
      ++i
    })
  })

  return <ul className="w-full">{ret}</ul>
}

AllNews.defaultProps = {
  imageMap: {},
}

export default AllNews
